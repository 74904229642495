<template>

  <vs-table :data="eventsEvaluationData" @selected="showEventsDetails"
            :pagination="eventsEvaluationData.length > maxItems ? true : false" :max-items="maxItems" search>
    <template slot="header">

      <div class="d-inline-block float-left">
        <h2 v-if="filter === 'active'">Aktivni dogodki (glasovanje v teku)</h2>
        <h2 v-if="filter === 'inactive'">Zaključeni dogodki (glasovanje zaključeno)</h2>
      </div>
      <vs-chip class="mt-1 ml-3">{{eventsEvaluationData.length}}</vs-chip>

    </template>
    <template slot="thead">
      <vs-th>
        Aktivnost
      </vs-th>
      <vs-th sort-key="name">
        Ime
      </vs-th>
      <vs-th sort-key="date">
        Datum
      </vs-th>
      <vs-th sort-key="notes">
        Opomba
      </vs-th>
      <vs-th>
        Akcije
      </vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="'active_' + index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr"
             @click="() => { selectedEventId = tr.id; }">

        <vs-td :data="data[index].voting_enabled">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <div class="avatarWithStatus">
              <vs-avatar size="large" :text="data[index].name"/>
              <vs-avatar v-if="data[index].voting_enabled === 1" size="small" class="status" color="success"
                         icon="check_circle"/>
              <vs-avatar v-if="data[index].voting_enabled === 0" size="small" class="status" color="danger"
                         icon="stop"/>
            </div>

          </vs-col>
        </vs-td>

        <vs-td :data="data[index].name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{data[index].name}}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].date">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{moment(data[index].date).format('DD. MM. YYYY')}}
          </vs-col>
        </vs-td>


        <vs-td :data="data[index].notes">

          <vs-tooltip :text="data[index].notes" title="Opomba" :key="data[index].id"
                      v-if="data[index].notes" position="left">
            <vs-avatar icon="note"/>
            <span>&nbsp;</span>
          </vs-tooltip>
          <div v-else>
            <em>/</em>
          </div>

        </vs-td>

        <vs-td :data="data[index]" @mousedown.stop="stopTheEvent">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <vs-button color="primary" icon="edit" @click.stop="editEvent(data[index].id)">Uredi</vs-button>

<!--            <vs-dropdown>-->
<!--              <vs-button class="btn-drop" type="filled" icon="expand_more">Akcije</vs-button>-->

<!--              <vs-dropdown-menu>-->
<!--                <vs-dropdown-item disabled>Ni akcij</vs-dropdown-item>-->
<!--              </vs-dropdown-menu>-->
<!--            </vs-dropdown>-->
          </vs-col>
        </vs-td>


        <template class="expand-user" slot="expand">

          <div class="flex mt-1 mb-0 w-full">
            <div class="flex-1" style="min-height: 186px;">

              <EventsStatistics :key="data[index].id + '_stats'" :eventId="data[index].id"></EventsStatistics>
            </div>
          </div>

        </template>


      </vs-tr>
    </template>
  </vs-table>

</template>

<script>
  import Vue from 'vue';

  import EventsStatistics from "./EventsStatistics";


  export default {
    name: 'events-table',
    props: {
      eventsEvaluationData: Array,
      filter: String
    },
    components: {

      EventsStatistics
    },

    data() {
      return {
        maxItems: 500,
        selectedEventId: null,
        selectedEventData: null
      }
    },

    computed: {},

    mounted() {
      const _this = this;

    },

    destroyed() {
      console.log("destroyed");
      const _this = this;

    },

    beforeDestroy() {
      console.log("beforeDestroy");
      const _this = this;


    },

    methods: {

      showEventsDetails(tr) {
        const _this = this;

        console.log("HERE!!!", tr);
        // _this.selectedEventId = tr.id;

        // _this.$vs.loading();
        //
        //
        // Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'event-evaluation/' + eventId)
        //   .then((res) => {
        //
        //     _this.selectedEventData =  res.data.data
        //     console.log("TOLE: ", res.data.data);
        //
        //     _this.$vs.loading.close()
        //   })
        //   .catch((error) => {
        //     console.log("error", error);
        //     _this.$vs.loading.close();
        //     return false
        //   });

      },

      getUnitDataById(unitId) {
        const _this = this;

        if (localStorage.getItem('userInfo')) {
          let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
          if (unit) {
            return unit.name + ' (' + unit.coveredMunicipalities + ')';
          } else
            return 'podatka ni bilo mogoče pridobiti';
        }

      },

      editEvent(eventId) {
        const _this = this;

        _this.$router.push({name: 'Dogodek', params: {eventId: eventId}})
      },

      stopTheEvent: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },


    }
  }
</script>

