<template>
  <div id="eventsEvaluationContainer">
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Dogodki</li>
    </vs-breadcrumb>


    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn" title="Dodaj nov dogodek" @click.stop="addEvent()"></vs-button>

    <div>

      <vs-tabs v-if="activeEventsData && inactiveEventsData">
        <vs-tab label="Aktivni dogodki (glasovanje v teku)" icon="check_circle" color="success" @click="">
          <EventsTable :eventsEvaluationData="activeEventsData" :filter="'active'" @showEventDetails="showEventDetails" />
        </vs-tab>
        <vs-tab label="Zaključeni dogodki (glasovanje zaključeno)" icon="radio_button_unchecked" @click="">
          <EventsTable :eventsEvaluationData="inactiveEventsData" :filter="'inactive'" @showEventDetails="showEventDetails" />
        </vs-tab>
      </vs-tabs>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="eventSidebar" spacer
                  v-model="sidebarActive">

        <Event v-if="eventId && sidebarActive" :eventId="eventId" />
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';

  import EventsTable from "../../components/Sopotniki/events/EventsTable";
  import Event from "../../components/Sopotniki/events/Event";


  export default {
    name: 'events-evaluation',

    components: {
      Event,
      EventsTable
    },

    data() {
      return {
        activeEventsData: null,
        inactiveEventsData: null,
        eventId: null,
        sidebarActive: false,
        maxItems: 500
      }
    },

    computed: {

    },

    mounted() {
      const _this = this;
      _this.getEvents();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.eventId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      getEvents() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'event-evaluation')
          .then((res) => {

            // _this.allCustomersData = res.data.data;
            _this.activeEventsData = _.filter(res.data.data, {voting_enabled: 1});
            _this.inactiveEventsData = _.filter(res.data.data, {voting_enabled: 0});
            console.log("TOLE: ", res.data.data);


            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showEventDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.eventId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      addEvent() {
        const _this = this;

        _this.$router.push({name: 'Dogodek'})
      }

    }
  }
</script>

